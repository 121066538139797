<template>
  <div>
 

 <div style="min-height:120px;"></div>
  <div class="container-fluid mt--6">





     <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">Users</h3>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="users">
            <el-table-column label="User's Name"
                             min-width="220px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.user.user_info.name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            
          <el-table-column label="Email"  min-width="200px"  prop="user.email">
             </el-table-column>
          
          <el-table-column label="Role"  min-width="150px"  prop="role">
             </el-table-column>
        
          <el-table-column label="Contact No."  min-width="150px"  prop="user.user_info.contact_number">
             </el-table-column>
             
          <el-table-column label="Address"  min-width="200px"  prop="user.user_info.address">
             </el-table-column>
            
           <el-table-column min-width="200px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
   
                  
                  
				
                  <base-button
                    @click.native="manageUser($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  Edit
                  </base-button>
                  
                  
                  
    
                  
    
                  
                 
                  
                </div>
            </el-table-column>   	
           
        </el-table>

       
    </div>
</div>
<!-- end clients list -->    
 <div style="min-height:120px;"></div>

    
 	<div class="container-fluid mt--6">
    
    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 users:[],
     	 code: "",
     	 
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/create_client';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Client Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		goToAddClient(){
  			this.$router.push( '/update-client/0')
  		},
  		viewClient(index, row) {
  			this.$router.push( '/manage-client/'+row.id)
  		
  		},
  		manageUser(index, row) {
  			this.$router.push( '/manage-user/'+row.user_id)
  		
  		},
  		viewReports(index, row) {
  			this.$router.push( '/view-reports/'+row.id)
  		},
  		addReport(index, row) {
  			//this.$router.push( '/add-report/'+row.id)
  			this.$router.push( '/add-report-init/'+row.id)
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/org_users';
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.users = response.data.users;
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














