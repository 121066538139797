<template>
  <div>
 

 <div style="min-height:120px;"></div>
 
   <div class="container-fluid mt--6">
   
   		<div style="text-align:right;padding:30px 15px;">
		<base-button
                    @click.native="goToAddAddress()"
                    class="edit"
                    type="default"
                    
                    icon
                  >
                  Add New Address
    		</base-button>
		</div>


   
   
    	  <card header-classes="bg-gradient-default">
            <div slot="header" class="row align-items-center">
              <div class="col-8 text-center">
                <h3 class="h3 text-white">
                	<span >Client Information</span>
        
                </h3>
              </div>
              
              
              <div class="col-4 text-right">
               <base-button type="warning" size="" class=""  @click.native="editClient()">
						Edit
					</base-button>
              </div>
              
              
            </div>

            <div>
                <span class="h6 surtitle">Client Name</span>
                <div class="h3 ">{{client.client_name}}</div>
             </div>
             
             
              <div style="margin-top:30px;">
                <span class="h6 surtitle">Client Email</span>
                <div class="h3 ">
                	<span> {{client.client_email}}</span>
                
                </div>
             </div>
             
             
              <div style="margin-top:30px;">
                <span class="h6 surtitle">Client Address</span>
                <div class="h3 ">
                	<span> {{client.client_address}}</span>
                
                </div>
             </div>
          	
          	
          	 <div style="margin-top:30px;">
                <span class="h6 surtitle">Contact Number</span>
                <div class="h3 ">
                	<span> {{client.contact_number}}</span>
                
                </div>
             </div>
             
             
              <div style="margin-top:30px;">
                <span class="h6 surtitle">Remarks</span>
                <div class="h3 ">
                	<span v-if="client.remarks"> {{client.remarks}}</span>
                	<span v-else>-</span>
                </div>
             </div>
             
        
          
          
          </card>
</div><!-- end container -->


 <div style="min-height:120px;"></div>

          
          
          
  <div class="container-fluid mt--6">

     <card v-for="address in addresses" header-classes="bg-gradient-success"  style="margin-bottom:100px;">
            <div slot="header" class="row align-items-center">
              <div class="col-8 text-center">
                <h3 class="h3 text-white">
                	<span >{{address.address}}</span>
        
                </h3>
              </div>
              
              
               <div class="col-4 text-right">
                	<base-button type="default" size="sm" class=""  @click.native="addBuilding(address)">
						<i class="text-white ni ni-fat-add"></i>

						Add Building
					</base-button>
              </div>
              
            </div>
            
      
        
          <div class="row" style="text-align:center;margin-bottom:50px">
                  	<div class="col-md-6">
                  		 <div style="margin-top:30px;">
							<span class="h6 surtitle" style="color:#525f7f">Location Address</span>
							<div >
								<span style="font-size:13px;"> {{address.address}}</span>
							</div>
						 </div>
					 </div>
					 <div class="col-md-6">	 
						 <div style="margin-top:30px;">
							<span class="h6 surtitle">Postal Code</span>
							<div >
								<span style="font-size:13px;"> {{address.postal_code}}</span>
							</div>
						 </div>
      				 </div>
          </div>
                  
       
	  <div v-for="building in address.client_buildings"   style="margin-bottom:100px;">
	  		
	  	<div style="text-align:center;"><h3>{{building.building_name}}</h3></div>
	  	
	    <div style="text-align:center;margin-bottom:25px;">
	    	<base-button type="default" size="sm" class=""  @click.native="addAsset(building)">
	    		<i class="text-white ni ni-fat-add"></i>

	    		Add Asset
	    	</base-button>

	    </div>
        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="building.cb_assets">
            <el-table-column label="Asset ID Tag"
                             min-width="120px"
                             prop="asset_tag_id"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.asset_tag_id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            
            
             <el-table-column label="Serial No"  prop="serial_num" min-width="120px">
             </el-table-column>
             
                <el-table-column label="Installation Date"  prop="installation_date_str" min-width="120px">
             </el-table-column>
            
            
             <el-table-column label="Location" sortable prop="location_description" min-width="120px">
             </el-table-column>
             
             <el-table-column label="Level/Unit" sortable prop="level_num" min-width="100px">
             </el-table-column>
             
        	<el-table-column label="Equipment"   min-width="180px"  >
        			 <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">Model No. <span style="font-weight:normal;">{{row.product.model_number}}</span>
                                                         

                             <span style="font-size:11px;">
                             <br/>Eq Subtype. <span style="font-weight:normal;">{{row.product.equipment_subtype.equipment_subtype_name}}</span>
                              
                              <br>Eq Type. <span style="font-weight:normal;">{{row.product.equipment_type.equipment_type_name}}</span>
                              <br/>Brand.  <span style="font-weight:normal;">{{row.product.brand.brand_name}}</span>
                             </span>
                            </span>
                        </div>
                    </div>
                </template>
             </el-table-column>
             
 
            
              <el-table-column label="Remarks" prop="remarks" min-width="120px">
             </el-table-column>
            
            
            
            
            
           <el-table-column  align="right" label="Actions" min-width="80px"> 
                <div slot-scope="{$index, row}" class="d-flex">
                             
					   <base-button
						@click.native="deleteAsset($index, row)"
						class="edit"
						type="warning"
						size="sm"
						icon
					  >
					  Delete
					  </base-button>
                  
                </div>
            </el-table-column>   	
           
        </el-table>
		<!-- end assets table-->
       
       </div> <!-- end building div -->
       
       
    </card>
</div>
<!-- end clients list -->    


    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 client:[],
     	 code: "",
     	 addresses:[],
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
    	addAsset(row) {
  			this.$router.push( '/add-asset/'+this.$route.params.cid+"/building/"+row.id)
    	},
    	deleteAsset(index, row) {
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are deleting this asset',
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 let uri = vm.$base_url+'/api/clients/post/delete_asset/'+row.id;
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Asset Deleted',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 }).then((result)=> {
										 
										 	vm.page_load();
										 
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal
  		
  		},
    	
    	addBuilding(address) {
  			this.$router.push( '/add-building/'+this.$route.params.cid+"/address/"+address.id)
    	},
    	editClient() {
  			this.$router.push( '/update-client/'+this.client.id)
  		
  		},
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/create_client';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Client Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewAsset(index, row) {
  			this.$router.push( '/manage-asset/'+row.id)
  		
  		},
  		goToAddAddress(){
  			this.$router.push( '/update-address/'+this.client.id+"/0")
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/client/'+this.$route.params.cid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.client = response.data.client;
				 this.addresses = response.data.client.client_addresses;
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














