<template>
  <div>
 

 <div style="min-height:120px;"></div>
  <div class="container-fluid mt--6">

     <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">Dashboard</h3>
        </div>
        
        
        <div v-if=0 style="margin-left:30px;margin-top:30px;margin-bottom:50px;">
        
			<h4>Current Features</h4>
			<ul>
				<li>Asset Management
						<ul>
							<li>Each client can have multiple addresses.</li>
							<li>Each address can have multiple buildings.</li>
							<li>Add asset to individual buildings.</li>
							<li>Each client's asset is an instance of a Equipment which has a Equipment Type and Equipment Subtype.</li>
						</ul/>
				</li>
				<li style="margin-top:20px;">CRM </li>
				<li style="margin-top:20px;">Report Management And Generation
					<ul>
						<li>Upload report photos.</li>
						<li>Add signatures for client and technicians.</li>
						<li>Generate and download report PDF.</li>
						<li>Quick email report PDF to client.</li>
					</ul>
				</li>
				<li style="margin-top:20px;">User Management
					<ul>
						<li>Different user roles: Technician and Admin.</li>
						<li>View details of users.</li>
					</ul>
				</li>
				
				<li style="margin-top:20px;">Checklist Management
					<ul>
						<li>Each Equipment Subtype is associated with a checklist.</li>
						<li>Checklists are automatically added to a new report when a client's asset having the corresponding Equipment Subtype is added.</li>
					</ul>
				</li>
				
			</ul>
			<br/>
			<br/>
			<br/>
			
			<h4>Mobile App (Upcoming)</h4>
			<ul>
				<li>Report Management And Generation</li>
			</ul>
			
        </div><!-- end body-->

       
    </div>
</div>
<!-- end clients list -->    
 <div style="min-height:120px;"></div>

  
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 reports:[],
     	 client: [],
     	 
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/create_client';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Client Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewClient(index, row) {
  			this.$router.push( '/manage-client/'+row.id)
  		
  		},
  		viewReport(index, row) {
  			this.$router.push( '/view-report/'+row.id)
  		},
  		editReport(index, row) {
  			this.$router.push( '/edit-report-details/'+row.id)
  		},
  		addReport(index, row) {
  			this.$router.push( '/add-report/'+row.id)
  		
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/all_reports';
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;

				 this.reports = response.data.reports;
				 
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
    	//this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














