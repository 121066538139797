<template>
  <div>
 
   
 <div style="min-height:120px;"></div>

    
 <div class="container-fluid mt--6">
    
            

	
	<!-- upload logo -->
     <div   class="col-lg-8">   
      <card>
       <!-- Card header -->
             <h3 slot="header" class="mb-0">Photos For Report {{report_id}}</h3>
       		
      
      		 <div class="row">
                  <div class="col-md-12">
                 
                 	
    	
                 	<h4>Upload New Report Photo</h4>
                           <input type="file" accept="image/x-png,image/jpeg" id="file" ref="new_file" class="btn btn-default" />

          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
            
     <!-- end row -->
    
    	<div class="row" style="margin-top:30px">
                  <div class="col-md-6">
                  
                  <base-input label="Remarks"
                      name="remarks"
                      placeholder=""
                      
                      v-model="model.remarks">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
    
    
     	<div style="margin-top:50px; text-align:left;">
			<base-button @click="uploadPhoto" type="info"  native-type="submit">Upload Photo</base-button>

     	</div>
     	
      </card>
      </div>
     
     
     <card v-for="img in images" class="col-lg-8">
     	<img v-bind:src="'data:image/jpeg;base64,'+img.image_path" style="max-width:100%" />
     	<div style="margin-top:15px;">
     		{{img.remarks}}
     	</div>
     	
     	<div style="margin-top:20px;text-align:right">
     	 <base-button
                    @click.native="deleteReportPhoto(img.id)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  Delete
                  </base-button>
     	</div>
     </card>

    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 is_create: 1,
     	 equipment_types:[],
     	 images:[],
     	 report_id :"",
     	 logo_path: 0,
     	 inputs: {
          fileSingle: [],
          fileMultiple: []
        },
     	 model:{
     	 	company_name:'',
     	 	address:'',
     	 	uen:'',

     	 },

     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				
  				vm.model.is_create  = this.is_create;
  				
  				let uri = vm.$base_url+'/api/clients/post/edit_organization';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Organization details updated',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
							
							vm.page_load();
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		uploadPhoto(){
    		
    			swal.showLoading();
  				var vm = this;
				
				let uploaded_file = this.$refs.new_file.files[0];
      			let formData = new FormData();
  			    formData.append('file', uploaded_file);    
  			    formData.append('report_id', this.$route.params.rid);
  			    formData.append('remarks', this.model.remarks);
  			    //formData.append('kd_remarks', this.new_key_document.remarks);
  			    
  			    
  			      				
  				let uri = vm.$base_url+'/api/clients/post/upload_report_photo';
				ApiService.post(uri, formData).then(response => {
					var msg = "Photo Uploaded.";
					
					
			
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: msg,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							}).then((result)=> {	
								vm.page_load();
							
							});
							
							
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 }).catch(
			 	error=>{
			 		swal.fire({
									title: 'Error',
									text: error.response.data.errors.file[0],
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 		
			 	}
			 )//end api service			
    	
    	
    	
    	
    	},
  		viewEquipment(index, row) {
  			this.$router.push( '/manage-equipment/'+row.id)
  		
  		},
  	
  		et_change(){
  			
			
  		},
  		deleteReportPhoto(photo_id){
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are deleting this report photo',
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 let uri = vm.$base_url+'/api/clients/post/delete_report_photo/' + photo_id;
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Photo Deleted',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 }).then((result)=> {
										 
										 	vm.page_load();
										 
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal
  		
  		
  		},
  		page_load(){
  			
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/report_photos/'+this.$route.params.rid;
			ApiService.get(uri).then(response => {
			 
				 const vm = this;
				  this.images = response.data.images;
				 this.report_id = this.$route.params.rid
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














