<template>
  <div>
 

 <div style="min-height:120px;"></div>
  <div class="container-fluid mt--6">

	
	
	
		
		
		
		
	
	 <card header-classes="bg-gradient-default">
            <div slot="header" class="row align-items-center">
              <div class="col-12 text-center">
                <h3 class="h3 text-white">
                	<span >Report No.</span> {{report.id}}</span>
        
                </h3>
              </div>
              
            </div><!-- end header -->
            
            
             	<base-button type="warning"  @click="editReport" style="margin-bottom:10px;">Edit Report</base-button>
             	<base-button type="warning"  @click="viewReportPhotos" style="margin-bottom:10px;">Photos</base-button>



            	<base-button type="success"  @click="add_client_signature" style="margin-bottom:10px;">Add Client Signature</base-button>
				<base-button type="success"  @click="add_my_signature" style="margin-bottom:10px;">Add My Signature</base-button>
				
				
				<base-button type="warning" v-if="flagged==1" @click="flag_report(0)" style="margin-bottom:10px;">This Report Has Been Flagged</base-button>
				<base-button type="success" v-if="!flagged" @click="flag_report(1)" style="margin-bottom:10px;">Flag Report</base-button>
				

				<base-button type="info"  @click="download_pdf" style="margin-bottom:10px;">Download PDF</base-button>
				<base-button type="info"  @click="email_report" style="margin-bottom:10px;">Email Report PDF To Client</base-button>


				<div   id="pdfDocumentDownloadLink" style="display:none;text-align:center;margin-bottom:50px;font-weight:bold;"></div>

            
       </card>     
		
		
		<pdf
			v-for="i in numPages"
			:key="i"
			:src="pdf64"
			:page="i"
			
		></pdf>
		
		

    
	
	
  <div  id="pdfDocument"></div>
	
	
	
 </div>
 <div style="min-height:120px;"></div>

  
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import pdf from 'vue-pdf'

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      pdf,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 report:[],
     	 client: [],
     	 checklists:[],
		 pdf64:'',
     	 pdf_link:'',
     	 numPages:0,
     	 flagged:0,
     	 model:{
     	 	checklist_items_cb:[],
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/update_report';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Report Updated.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewClient(index, row) {
  			this.$router.push( '/manage-client/'+row.id)
  		
  		},
  	
  		editReport(index, row) {
  			this.$router.push( '/edit-report-details/'+this.$route.params.rid)
  		},
  		addReport(index, row) {
  			this.$router.push( '/add-report/'+row.id)
  		
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		add_client_signature(){
  			this.$router.push( '/add-signature/'+this.$route.params.rid+"/1");
  			
  		},
  		add_my_signature(){
  			this.$router.push( '/add-my-signature/'+this.$route.params.rid+"/1");
  			
  		},
  		download_pdf(){
  			//window.open(this.pdf_link, '_blank');
  			document.getElementById('pdfDlLink').click();
  		},
  		viewReportPhotos(){
  			this.$router.push( '/manage-report-photos/'+this.$route.params.rid)
  		},
  		flag_report(to_flag){
  			var f_txt1 = "You are flagging this report for follow-up.";
  			var f_txt2 = "Report Flagged.";
  			
  			if(to_flag ==0){
  				f_txt1 = "You are unflagging this report.";
  				f_txt2 = "Report Unflagged."
  			}
  			
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: f_txt1,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  		  
				  		 if (result.isConfirmed) {
				  			alert(f_txt1);
				  		
				  			swal.showLoading();
				  		 	 let uri = vm.$base_url+'/api/reports/get/flag_report/'+ this.$route.params.rid;
							 ApiService.get(uri).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: f_txt2,
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
										  swal.close();
										 vm.page_load();
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  	 },
  		email_report(){
  			swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/reports/get/email_report/'+ this.$route.params.rid;
				ApiService.get(uri).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Report emailed to client.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		
  		
  		
  		
  		
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/reports/get/view_report_pdf/'+this.$route.params.rid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;

				 this.report = response.data.report;
				 this.flagged = response.data.report_short.flagged;
				 //this.pdf64 = 'data:application/pdf;base64,'+response.data.pdf;
				 this.pdf64 = pdf.createLoadingTask('data:application/pdf;base64,'+response.data.pdf);
				 
				 
				 this.pdf64.promise.then(pdf => {

					this.numPages = pdf.numPages;
				});
				 
				 ////////////////////
				 // start display pdf
				 ////////////////////
				 
				 let b64 = response.data.pdf;
				
					// Embed the PDF into the HTML page and show it to the user
					var obj = document.createElement('object');
					obj.style.width = '100%';
					obj.style.height = '842pt';
					//obj.title = this.doc.document_name;
					obj.title = "Report";
					obj.type = 'application/pdf';
					obj.data = 'data:application/pdf;base64,' + b64;
					//document.getElementById("pdfDocument").appendChild(obj);
					
					// Insert a link that allows the user to download the PDF file
					var link = document.createElement('a');
					link.id = 'pdfDlLink'
					link.innerHTML = 'Download PDF file';
					link.download = "report"+'.pdf';
					link.href = 'data:application/octet-stream;base64,' + b64;
					document.getElementById("pdfDocumentDownloadLink").appendChild(link);
					
					this.pdf_link = link.href;
				 
				 ////////////////////
				 // end display pdf
				////////////////////
				
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  	 }
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














