import ApiService from './api.service'
import { TokenService } from './storage.service'


class AuthenticationError extends Error {
    constructor(errorCode, message) {
        super(message)
        this.name = this.constructor.name
        this.message = message
        this.errorCode = errorCode
    }
}

const UserService = {
    /**
     * Login the user and store the access token to TokenService. 
     * 
     * @returns access_token
     * @throws AuthenticationError 
    **/
    

    
    login: async function(email, password) {
    	    let burl = process.env.VUE_APP_API_PATH;
    	    //let burl = "https://rp.rollingatlas.com/rpa/public";
        const requestData = {
            method: 'post',
            url: burl+"/api/login",
            //url: "https://api-cs.easilycorp.com/csp/cs/public/api/v1/login",
            data: {
              //  grant_type: 'password',
                email: email,
                password: password
            },
           // auth: {
           //     username: process.env.VUE_APP_CLIENT_ID,
           //     password: process.env.VUE_APP_CLIENT_SECRET
          //  }
        }
		
        try {
            const response = await ApiService.customRequest(requestData)
            if(response.data.success){
				TokenService.saveToken(response.data.access_token)
				//TokenService.saveRefreshToken(response.data.refresh_token)
				ApiService.setHeader()
			
				if(response.data.user_info){
					let ui = response.data.user_info;
					let user_info = {
							//"first_name": ui.first_name,
							//"last_name": ui.last_name,
							"role": ui.role,
					}
				
			
					TokenService.saveUserInfo(JSON.stringify(user_info));
				}// if user_info
				
				if(response.data.role){
					TokenService.saveUserRole(response.data.role);
				}
				
            }
            
           // ApiService.mount401Interceptor();
			return response;
            //return response.data.token
        } catch (error) {
          //  throw new AuthenticationError(error.response.status, error.response.data.detail)
        }
    },

    /**
     * Refresh the access token.
    **/
    refreshToken: async function() {
        const refreshToken = TokenService.getRefreshToken()

        const requestData = {
            method: 'post',
            url: "/o/token/",
            data: {
                grant_type: 'refresh_token',
                refresh_token: refreshToken
            },
            auth: {
                username: process.env.VUE_APP_CLIENT_ID,
                password: process.env.VUE_APP_CLIENT_SECRET
            }
        }

        try {
            const response = await ApiService.customRequest(requestData)

            TokenService.saveToken(response.data.access_token)
            TokenService.saveRefreshToken(response.data.refresh_token)
            // Update the header in ApiService
            ApiService.setHeader()

            return response.data.access_token
        } catch (error) {
            throw new AuthenticationError(error.response.status, error.response.data.detail)
        }

    },

    /**
     * Logout the current user by removing the token from storage. 
     * 
     * Will also remove `Authorization Bearer <token>` header from future requests.
    **/
    logout() {
        // Remove the token and remove Authorization header from Api Service as well 
        TokenService.removeToken()
        TokenService.removeRefreshToken()
        TokenService.removeUserInfo()
        ApiService.removeHeader()
        
        // NOTE: Again, we'll cover the 401 Interceptor a bit later. 
        //ApiService.unmount401Interceptor()
    }
}

export default UserService

export { UserService, AuthenticationError }