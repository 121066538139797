<template>
  <div>
 

 <div style="min-height:120px;"></div>


    
 <div class="container-fluid mt--6">
    
	  <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Update Equipment Subtype</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-12">
                  
                  <base-input label="Equipment Subtype Name"
                      name="equipment subtype name"
                      placeholder=""
                      required
                      v-model="model.equipment_subtype_name">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                
                
                
                <div class="row">
                  <div class="col-md-12">
                    <base-input required label="Checklist">
                      <el-select v-model="model.checklist_id" 
                      			 filterable 
                                 placeholder="Checklist">
                        <el-option v-for="option in checklist_all_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    
                  </div>
                </div>
                <!-- end row -->
                
                
             
                
                <base-input label="Remarks">
                    <textarea class="form-control" v-model="model.remarks"  rows="3"></textarea>
                 </base-input>
                  
                  
                
                <div>
                <base-button type="info" block native-type="submit">Update Equipment Subtype</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div>
            



    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 checklists:[],
     	 
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 },
     	 et_type_options:[],
     	 checklist_options:[],
     	 checklist_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/update_equipment_subtype';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Equipment Subtype Updated.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							}).then(response => {
								vm.page_load();
							});
							
							
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewChecklist(index, row) {
  			this.$router.push( '/manage-checklist/'+row.id)
  		
  		},
  	
  		et_change(){
  			
  			this.checklist_options = this.checklist_all_options[this.model.checklist_id][0];
  			this.model.checklist_id = this.checklist_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/equipment_subtype_info/'+this.$route.params.estid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.model = response.data.eq_subtype;
				 this.checklist_all_options = response.data.checklist_all_options;
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














