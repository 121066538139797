<template>
  <div>
 

 <div style="min-height:120px;"></div>
 
   <div class="container-fluid mt--6">
   
       <div class="row">
        <div class="col-lg-6">
			<card >
				<div slot="header" class="row align-items-center">
				  <div class="col text-center">
					<h3 class="h3 ">
						<span >Select Assets For New Report</span>
		
					</h3>
				  </div>
				</div>

				<div style="text-align:center;">
					<span class="h6 surtitle">Client</span>
					<div class="h3 ">{{client.client_name}}</div>
				 </div>
			 
			
			 
		
		  
		  
			  </card>
		</div>
	</div> <!-- end row-->	  
			  
</div><!-- end container -->


 <div style="min-height:120px;"></div>
 
 
 <!-- <div class="row">
  	  <div v-for="address in addresses" class="col-md-6" style="" >
  		<card  class="card" style="margin-bottom:150px;" header-classes="bg-gradient-default">
  			 <div slot="header" class="row align-items-center">
              <div class="col text-center">
                <h3 class="h3 text-white">
                	<span >{{address.address}}</span>
        
                </h3>
              </div>
            </div>
  		
  		</card>
  	</div>
  	
  </div> -->

          
          
          
  <div class="container-fluid mt--6">

     <card v-for="address in addresses" class="card" style="margin-bottom:20px;" header-classes="bg-gradient-default">
      <div slot="header" class="row align-items-center">
              <div class="col-8 text-center">
                <h3 class="h3 text-white">
                	<span >{{address.address}}</span>
        
                </h3>
              </div>
              
              
               <div class="col-4 text-right">
               <base-button type="warning" size="" class=""  @click.native="showAddress(address.id)">
						Show/Hide
				</base-button>
              </div>
              
            </div> <!-- end header-->
        
    <div v-if="address_hideshow[address.id]" class="row" style="text-align:center;margin-bottom:50px">
                  	<div class="col-md-6">
                  		 <div style="margin-top:30px;">
							<span class="h6 surtitle" style="color:#525f7f">Location Address</span>
							<div >
								<span style="font-size:13px;"> {{address.address}}</span>
							</div>
						 </div>
					 </div>
					 <div class="col-md-6">	 
						 <div style="margin-top:30px;">
							<span class="h6 surtitle">Postal Code</span>
							<div >
								<span style="font-size:13px;"> {{address.postal_code}}</span>
							</div>
						 </div>
      				 </div>
          </div>
                  
       
	  <div v-if="address_hideshow[address.id]" v-for="building in address.client_buildings" style="margin-bottom:100px;">
	  		
	  	<div style="text-align:center;margin-bottom:35px;"><h3>{{building.building_name}}</h3></div>
	  	
	    <div  style="text-align:center;margin-bottom:50px;">
	    	<base-button type="default" size="sm" class=""  @click="checkAllSingleBuilding(building.id)">
	    		Select All
	    	</base-button>
	    	
	    	<base-button type="warning" size="sm" class=""  @click="uncheckAllSingleBuilding(building.id)">
	    		Unselect All
	    	</base-button>

	    </div>
        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="building.cb_assets">
        	
        	<el-table-column  align="left" label="Select Asset ID Tag" min-width="250px" sortable prop="asset_tag_id">
                <div slot-scope="{$index, row}" class="d-flex">
                            
                   <base-input >
					  <base-checkbox v-model="model.assets_cb[building.id][row.id]"  >
							<span class="font-weight-600 name mb-0 text-sm">{{row.asset_tag_id}}</span>
					  </base-checkbox>
					</base-input> 

                  
                  
                </div>
            </el-table-column>
            <!--
            <el-table-column label="Asset ID Tag"
                             min-width="140px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.asset_tag_id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            -->
            
            
            <el-table-column label="Equipment"   min-width="180px"  >
        			 <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">Model.  <span style="font-weight:normal;">{{row.product.model_number}}</span>
                                                         

                             <span style="font-size:11px;">
                             <br/>Eq Subtype. <span style="font-weight:normal;">{{row.product.equipment_subtype.equipment_subtype_name}}</span>
                              <br/>{{row.product.brand.brand_name}}
                             </span>
                            </span>
                        </div>
                    </div>
                </template>
             </el-table-column>
             
             
             <el-table-column label="Location" sortable prop="location_description" min-width="120px">
             </el-table-column>
             
             <el-table-column label="Level/Unit" sortable prop="level_num" min-width="130px">
             </el-table-column>
             
             
            
             
              <el-table-column label="Remarks" min-width="250px"  prop="remarks">
             </el-table-column>
            
            
            
            
            
              	
           
        </el-table>
		<!-- end assets table-->
       
       </div> <!-- end building div -->
       
       
    </card> <!-- end addresses -->
</div>
<!-- end clients list -->   


	 		<div style="max-width:90%;text-align:center;margin-left:auto;margin-right:auto;">
                <base-button type="info" block @click="submit_report" >Create Report</base-button>
             </div> 
	

    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 client:[],
     	 code: "",
     	 addresses:[],
     	 address_hideshow:[],
     	 building_asset_ids:[],
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 	assets_cb: [],
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
    	showAddress(address_id){
    		
    		if(!this.address_hideshow[address_id]){
    			this.address_hideshow[address_id] = true;
    		}
    		else{
    			this.address_hideshow[address_id] = false;
    		}
    	},
    	checkAllSingleBuilding(building_id){
    		//this.model.assets_cb[building_id];
    		
    		var vm = this;
    		this.building_asset_ids[building_id].forEach(function(aid) {
				vm.model.assets_cb[building_id][aid] = true;
				
				//console.log("debug: "+vm.model.assets_cb[building_id][aid]);
				console.log("debug: "+vm.model.assets_cb);
			});
		
    	},
    	uncheckAllSingleBuilding(building_id){
    		
    		var vm = this;
    		this.building_asset_ids[building_id].forEach(function(aid) {
				vm.model.assets_cb[building_id][aid] = false;
			});
		
    	},
    	addAsset(row) {
  			this.$router.push( '/add-asset/'+this.$route.params.cid+"/building/"+row.id)
    	},
  		submit_report(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/add_report';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				
			 				
			 				vm.$router.push( '/edit-report-details/'+ response.data.report_id)
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewAsset(index, row) {
  			this.$router.push( '/manage-asset/'+row.id)
  		
  		},
  		selectAsset(row){
  			//alert("d:"+this.model.assets);
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
  			
			let uri = this.$base_url+'/api/clients/get/client_for_report/'+this.$route.params.cid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.client = response.data.client;
				 this.addresses = response.data.client.client_addresses;
				 this.model.assets_cb = response.data.assets_cb;
				 
				 this.model.client_id = this.$route.params.cid;
				 
				 this.building_asset_ids = response.data.building_asset_ids;
				 this.address_hideshow = response.data.address_hideshow;
				 
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














