<template>
  <div>
 

 <div style="min-height:120px;"></div>
    
 
 
 
  
 <div class="container-fluid mt--6">
    
      <div class="row">
        <div class="col-lg-6">
        	<card header-classes="bg-gradient-default">
				<div slot="header" class="row align-items-center">
				  <div class="col text-center">
					<h3 class="h3 text-white">
						<span >Client & Asset Information</span>
		
					</h3>
				  </div>
				</div>
				
				
			 <div>
                <span class="h6 surtitle">Client Name</span>
                <div class="h3 ">{{client.client_name}}</div>
             </div>
             
             
              <div style="margin-top:30px;">
                <span class="h6 surtitle">Asset Address</span>
                <div class="h3 ">{{building.client_address.address}}</div>
             </div>
             
             
            <div style="margin-top:30px;">
                <span class="h6 surtitle">Building That Asset Is Located In</span>
                <div class="h3 ">{{building.building_name}}</div>
             </div>
             
             
             

			</card>
        
        </div>
    </div>
    <!-- end row -->
    

      <!--start new row-->
      
      <div class="row">
        <div class="col-lg-8">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Create Asset</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        
        		
        		
        		
        	 <div class="row">
                  <div class="col-md-12">
                    <base-input required label="Equipment">
                      <el-select v-model="model.product_id" filterable
                      			
                                 placeholder="Model Name (Brand)">
                        <el-option v-for="option in product_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    
                  </div>
                </div>
                <!-- end row -->
                
                
        
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Asset ID"
                      name="asset ID"
                      placeholder=""
                      required
                      v-model="model.asset_tag_id">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                  <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Serial No."
                      name="serial no"
                      placeholder=""
                      
                      v-model="model.serial_num">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                  <div class="row">
                  <div class="col-md-6">
                  
                    <base-input  type="date" label="Date Of Installation"  v-model="model.installation_date"  id="installation date"/>

        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                 
                 
                 
                 
                 
                 <div class="row">
                  <div class="col-md-12">
                  
                  <base-input label="Location Description"
                      name="model number"
                      placeholder="Describe the location (optional)"
                      
                      v-model="model.location_description">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                 <div class="row">
                  <div class="col-md-4">
                  
                  <base-input label="Level & Unit Number"
                      name="level & unit number"
                      placeholder="(optional)"
                      
                      v-model="model.level_num">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                  <base-input label="Remarks">
                    <textarea class="form-control" v-model="model.remarks"  rows="3"></textarea>
                 </base-input>
                 
                 
                 
                 
           
                
                
             
                
               
                  
                  
                
                <div>
                <base-button type="info" block native-type="submit">Add Asset To Building</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div>
            
      
      
      
     




    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 building:{
     	 	client_address:[],
     	 	
     	 },
     	 client: [],
     	 
     	 model:{ 
     	 	client_id: 0,
     	 	client_building_id: 0,
     	 	client_address_id: 0,
     	 	product_id: null,
     	 },
     	 product_options:[],
     	 
     	 selectOptions: [
          {
            label: 'Alerts',
            value: 'Alerts'
          },
          {
            label: 'Badges',
            value: 'Badges'
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/add_asset';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Asset Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		et_change(){
  			
  		
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/add_asset_info/'+ this.$route.params.cid +"/"+this.$route.params.bid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.client = response.data.client;
				 this.building = response.data.building;
				 this.product_options = response.data.products;
				 
				 this.model.client_id = this.$route.params.cid;
				 this.model.client_building_id = this.$route.params.bid; 
				 
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














