<template>
  <div v-if="is_admin ==1">
 

 <div style="min-height:120px;"></div>
  <div class="container-fluid mt--6">

     <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">Organizations</h3>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="organizations">
            <el-table-column label="ID"
                            
                             prop="id"
                             >
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            
         <el-table-column label="Organization Name"  min-width="130px" sortable  prop="company_name">
             </el-table-column>
            
             
             
            
           <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                             
			<!--
                  <base-button
                    @click.native="viewEquipment($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                Edit
                  </base-button>
                  -->
                
                  
                  
                </div>
            </el-table-column>   	
           
        </el-table>

       
    </div><!-- end org -->
    
    
     <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">Users</h3>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="users">
            <el-table-column label="ID"
                            
                             prop="id"
                             >
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            
         <el-table-column label="Email"  min-width="130px" sortable  prop="email">
             </el-table-column>
            
         <el-table-column label="Role">
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span v-if="row.user_role" class="font-weight-600 name mb-0 text-sm">{{row.user_role.role}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        
        
        <el-table-column label="Organization ID">
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span v-if="row.user_role" class="font-weight-600 name mb-0 text-sm">{{row.user_role.organization_id}}</span>
                        </div>
                    </div>
                </template>
        </el-table-column>
            
            
            
             
            
           <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                             
			<!--
                  <base-button
                    @click.native="viewEquipment($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                Edit
                  </base-button>
                  -->
                
                  
                  
                </div>
            </el-table-column>   	
           
        </el-table>

       
    </div><!-- end users -->


</div>
 <div style="min-height:120px;"></div>

    
 <div class="container-fluid mt--6">
    
	  <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Create Organization</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_create_org_form">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Organization Name"
                      name="organization name"
                      placeholder=""
                      required
                      v-model="model_org.company_name">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                
    
                
                <div>
                <base-button type="info" block native-type="submit">Create Organization</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div> <!-- end row with card -->
            
    
    
      <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Edit User</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_edit_user_form">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="User ID"
                      name="user ID"
                      placeholder=""
                      required
                      v-model="model_user.user_id">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                 
                  <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Organization ID"
                      name="organization ID"
                      placeholder=""
                      required
                      v-model="model_user.org_id">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                
                
               <div class="row">
                  <div class="col-md-6">
                    <base-input required label="Role">
                      <el-select v-model="model_user.role" 
                                 placeholder="Select User Role">
                        <el-option v-for="option in user_role_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    
                  </div>
                </div>
                <!-- end row -->
    
                
                <div>
                <base-button type="info" block native-type="submit">Update User</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div> <!-- end row with card -->
            


		
		<base-button type="warning"  @click="bulk_import_types_subtypes">Bulk Import Equipment Types & Subtypes</base-button>

		
		
    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 is_create: 1,
     	 is_admin: 0,
     	 organizations:[],
     	 users:[],
     	 model_user:{
     	 	user_id: '',
     	 	role: '',
     	 	org_id: '',
     	 },
     	 model_org:{
     	 	company_name:'',
     	 	address:'',
     	 	uen:'',
     	 
     	 },
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 },

     	 user_role_options: [
          {
            label: 'Admin',
            value:'admin'
          },
          {
            label: 'Technician',
            value: 'tech',
          },
        ],
	
      };
    },
    methods: {
  		submit_create_org_form(){
  				swal.showLoading();
  				var vm = this;
  				
  				vm.model.is_create  = this.is_create;
  				
  				let uri = vm.$base_url+'/api/admin/post/create_org';
				ApiService.post(uri, vm.model_org).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'New organization created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
							
							vm.page_load();
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		submit_edit_user_form(){
  				swal.showLoading();
  				var vm = this;
  				
  				vm.model.is_create  = this.is_create;
  				
  				let uri = vm.$base_url+'/api/admin/post/edit_user';
				ApiService.post(uri, vm.model_user).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'User details updated.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
							
							vm.page_load();
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewEquipment(index, row) {
  			this.$router.push( '/manage-equipment/'+row.id)
  		
  		},
  	
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/admin/get/admin_console';
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.organizations = response.data.organizations;
				 this.users = response.data.users;
				 swal.close();
				 this.is_admin = 1;
   
			})
  		
  		
  		
  		},
  		bulk_import_types_subtypes(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are bulk importing equipment types and subtypes.',
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	
				  			 let uri = vm.$base_url+'/api/admin/get/bulk_import_types';
							 ApiService.get(uri).then(response => {
			 						if(response.data.success == "y"){
			 							swal.fire({
												title: `Success`,
												text: 'Bulk import successful.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














