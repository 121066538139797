<template>
  <div>
    <div class="header text-center">
      <h3 class="title">Logging Out</h3>
    </div>

  </div>

</template>
<script>
  import {UserService} from '@/services/user.service'

  
  export default {
    beforeCreate() {
       UserService.logout();
       this.$router.push( '/login')
    }
  }
</script>
<style>
</style>
