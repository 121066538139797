<template>
  <div>
 

 <div style="min-height:120px;"></div>
    
    
 <div class="container-fluid mt--6">
    

      <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 v-if="is_create == 1" slot="header" class="mb-0">Create Equipment</h3>
              <h3 v-else slot="header" class="mb-0">Update Equipment</h3>
              
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        
                
                 
                 
                 <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Model"
                      name="model number"
                      placeholder="Model"
                      required
                      v-model="model.model_number">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                <div class="row">
                  <div class="col-md-6">
                    <base-input required label="Equipment Type">
                      <el-select v-model="model.equipment_type_id" filterable
                      			 @change="et_change"
                                 placeholder="Equipment Type">
                        <el-option v-for="option in et_type_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    
                  </div>
                </div>
                <!-- end row -->
                
                
                <div class="row">
                  <div class="col-md-6">
                    <base-input required label="Equipment Subtype">
                      <el-select v-model="model.equipment_subtype_id" 
                                 placeholder="Equipment Subtype">
                        <el-option v-for="option in et_subtype_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    
                  </div>
                </div>
                <!-- end row -->
                
                
                
                <div class="row">
                  <div class="col-md-6">
                    <base-input required label="Brand">
                      <el-select v-model="model.brand_id" 
                                 placeholder="">
                        <el-option v-for="option in brand_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-6">
                    
                  </div>
                </div>
                <!-- end row -->
                
                <base-input label="Remarks">
                    <textarea class="form-control" v-model="model.remarks"  rows="3"></textarea>
                 </base-input>
                  
                  
                
                <div>
                
                	<base-button v-if="is_create == 1" type="info" block native-type="submit">Add Equipment</base-button>
                	<base-button v-else type="info" block native-type="submit">Update Equipment</base-button>

                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div>
            
      
      
      
     




    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 brand_options:[],
     	 
     	 is_create: 1,
     	 model:{
     	 	model_number:'',
     	 	product_name:'',
     	 	equipment_subtype_id: '',
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 selectOptions: [
          {
            label: 'Alerts',
            value: 'Alerts'
          },
          {
            label: 'Badges',
            value: 'Badges'
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				
  				vm.model.is_create  = this.is_create;
  				
  				let uri = vm.$base_url+'/api/products/post/create_product';
				ApiService.post(uri, vm.model).then(response => {
				
					var msg = "Product Created.";
					
					if(vm.model.is_create ==  0){
						msg = "Product Details Updated."
					}
					
					
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: msg,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type_id][0];
  			this.model.equipment_subtype_id = this.et_subtype_options[0]['value'];
			
  		},
  		get_product(){
  		
  			let uri_1 = this.$base_url+'/api/products/get/product/'+this.$route.params.pid;
				ApiService.get(uri_1).then(response => {
			 
						 const vm = this;
						 this.model = response.data.product;
						 
						 this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type_id][0];

						 swal.close();
   
			})
  		
  		
  		},
  		page_load(){
  			swal.showLoading();

  			let uri = this.$base_url+'/api/products/get/list';
			ApiService.get(uri).then(response => {
			 
					 const vm = this;
					 this.et_type_options = response.data.et_arr;
					 this.et_subtype_all_options = response.data.est_arr;
					 this.brand_options = response.data.brands_arr;
					 
   					
   					 if(vm.$route.params.pid != 0){
							vm.get_product();

					} 
			})
				
				
  			if(this.$route.params.pid == 0){
  				this.is_create = 1;
  				swal.close();
  			
  			}
  			else{
  				this.is_create = 0;
  				
  				

  			}//end if-else
  			
			
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














