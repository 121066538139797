var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"min-height":"120px"}}),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.parameters}},[_c('el-table-column',{attrs:{"label":"Name Of Parameter","min-width":"210px","prop":"parameter_name","sortable":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.parameter_name))])])])]}}])}),_c('el-table-column',{attrs:{"label":"Equipment Subtype","min-width":"210px","sortable":"","prop":"equipment_subtype.equipment_subtype_name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{},_vm._l((row.equipment_subtypes),function(eq_st){return _c('div',{staticStyle:{"margin-bottom":"20px"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(eq_st.equipment_subtype_name))]),_c('br'),_c('span',{staticStyle:{"font-size":"11px"}},[_c('span',{staticClass:"font-weight-600 name mb-0 "},[_vm._v("Eq Type: ")]),_c('span',[_vm._v(" ("+_vm._s(eq_st.equipment_type.equipment_type_name)+")")])])])}),0)]}}])}),_c('el-table-column',{attrs:{"min-width":"180px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[_c('base-button',{staticClass:"edit",attrs:{"type":"warning","size":"sm","icon":""},nativeOn:{"click":function($event){return _vm.editParameter($index, row)}}},[_vm._v(" Edit ")])],1)}}])})],1)],1)]),_c('div',{staticStyle:{"min-height":"120px"}}),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('div',{staticClass:"card-wrapper"},[_c('card',[_c('h3',{staticClass:"mb-0",attrs:{"slot":"header"},slot:"header"},[_vm._v("Create Parameter")]),_c('form',{staticClass:"needs-validation",on:{"submit":function($event){$event.preventDefault();return _vm.submit_form($event)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-input',{attrs:{"label":"Parameter Name","name":"parameter name","placeholder":"","required":""},model:{value:(_vm.model.parameter_name),callback:function ($$v) {_vm.$set(_vm.model, "parameter_name", $$v)},expression:"model.parameter_name"}})],1),_c('div',{staticClass:"col-md-6"})]),(0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('base-input',{attrs:{"required":"","label":"Equipment Subtype"}},[_c('el-select',{attrs:{"filterable":"","placeholder":"Equipment Subtype (Eq Type)"},model:{value:(_vm.model.equipment_subtype),callback:function ($$v) {_vm.$set(_vm.model, "equipment_subtype", $$v)},expression:"model.equipment_subtype"}},_vm._l((_vm.et_subtype_all_options),function(option){return _c('el-option',{key:option.label,attrs:{"label":option.label,"value":option.value}})}),1)],1)],1),_c('div',{staticClass:"col-md-6"})]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.et_subtype_all_options}},[_c('el-table-column',{attrs:{"align":"left","label":"Select Equipment Subtype (Eq Type)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[_c('base-input',[_c('base-checkbox',{model:{value:(_vm.model.est_checkboxes[row.value]),callback:function ($$v) {_vm.$set(_vm.model.est_checkboxes, row.value, $$v)},expression:"model.est_checkboxes[row.value]"}},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.label))])])],1)],1)}}])})],1)],1)]),_c('base-input',{attrs:{"label":"Remarks"}},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.model.remarks),expression:"model.remarks"}],staticClass:"form-control",attrs:{"rows":"3"},domProps:{"value":(_vm.model.remarks)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.model, "remarks", $event.target.value)}}})]),_c('div',[_c('base-button',{attrs:{"type":"info","block":"","native-type":"submit"}},[_vm._v("Create Parameter")])],1)],1)])],1)])])]),_vm._v(" "),_c('div',{staticStyle:{"min-height":"200px"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("Parameters")])])}]

export { render, staticRenderFns }