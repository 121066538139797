<template>
  <div>
 
 
 
  <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Edit Report Details</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
           
          </nav>
        </div>
        <div class="col-lg-6 col-5 text-right">
          
        </div>
      </div>
    </base-header>
    
    



    

  <div class="container-fluid mt--6">
  
      <card>
   			<div class="row">
					<div class="col-lg-12">
						<base-button type="danger" @click="delete_report()" style="margin-top:10px;"  >Delete Report</base-button>
						  <base-button type="warning" @click="changeTab(1)" style="margin-top:10px;"  >Edit Report Details</base-button>
						  <base-button type="warning" @click="changeTab(2)" style="margin-top:10px;" >Edit Assets</base-button>
						   <base-button type="success" @click="submit_form()"  style="margin-top:10px;">Save Report</base-button>
						   
						  <base-button type="info" @click="generate_report()" style="margin-top:10px;" >Generate New PDF Report</base-button>
						   <base-button v-if="pdf_exist==1" type="info" @click="viewReport" style="margin-top:10px;" >View Report PDF</base-button>

					</div>
					
       		 </div>
     </card>
        
        
        
	
	<div class="row">
        <div class="col-lg-12">
        
    <!-- start card -->
    <card v-if="showTab == 1">
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Report Details (ID: {{report.id}}) For {{report.client.client_name}}</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        		
        		
        		<div class="row">
				  <div class="col-md-3">
						<div style="margin-top:30px; margin-bottom:30px;">
							<span class="heading-title text-warning" style="font-weight:bold">Basic</span>
						</div>
				  </div>	
				</div>
        		
        		
        		
        		 <div class="row">
                  <div class="col-md-3">
                    <base-input required label="Service Type">
                      <el-select  required v-model="model.report_type" >
                        <el-option v-for="option in report_type_options"
                                   :key="option.label"
                                   :label="option.label"
                                   :value="option.value">
                        </el-option>
                      </el-select>
                    </base-input>
                  </div>
                  <div class="col-md-3">
                                         <base-input required type="date" label="Report Date"  v-model="model.report_datetime"  id="report date"/>

                  </div>
                  
                  
                   <div class="col-md-3">
                  
                  
                  <!-- originally client PO no-->
                 		 <base-input label="Reference No."
                      name="client po no"
                      placeholder=""
                      v-model="model.client_po_num">
          			</base-input>
                  </div>
                  
                  
                  
                 
                  
                  
                  
                  
                </div>
                <!-- end row -->
                
                
                

                 
                 
                <div class="row">
                	 <div class="col-md-3">
                  
                     <base-input  type="date" label="Service Start Date"  v-model="model.service_datetime"  id="service date"/>

                  </div>
                  
                  <div class="col-md-3">
                  
                     <base-input  type="date" label="Service End Date"  v-model="model.service_to_datetime"  id="service end date"/>

                  </div>
                
                  
                  
                  	<div class="col-md-3">                  
						<base-input label="Time In"
						  name="time_in"
						  placeholder=""
						  v-model="model.time_in">
						</base-input>
                  </div>
                  
                  <div class="col-md-3">                  
					  <base-input label="Time Out"
						  name="time_out"
						  placeholder=""
						  v-model="model.time_out">
						</base-input>
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
            
                      
                 
                 
                <div class="row">
                  <div class="col-md-3">
                  
                  <base-input label="Report By"
                      name="report by"
                      placeholder=""
                      v-model="model.reported_by">
          			</base-input>
                  </div>
                  
                 <div class="col-md-3">
                  
					  <base-input label="Attended By"
						  name="attended by"
						  placeholder=""
						  v-model="model.attended_by">
						</base-input>
                  </div>
                </div>
                
                 <!-- end row -->



                 
                   <hr/> 
               
				
				
				<div class="row">
				  <div class="col-md-3">
						<div style="margin-top:50px; margin-bottom:30px;">
							<span class="heading-title text-warning"  style="font-weight:bold">Signature</span>
						</div>
				  </div>	
				</div>
                 
                 
                   <div class="row">
                  <div class="col-md-6">                  
						<base-input label="Name For Client Signature"
						  name="client_sign_name"
						  placeholder=""
						  v-model="model.client_sign_name">
						</base-input>
                  </div>
                  
                  <div class="col-md-6">                  
					  <base-input label="Name For Tech Signature"
						  name="tech_sign_name"
						  placeholder=""
						  v-model="model.tech_sign_name">
						</base-input>
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                 
                 
                 <div class="row" style="margin-bottom:50px;">
                  <div class="col-md-6">
                  
                     <base-input type="date" label="Date Of Client Signature"  v-model="model.client_sign_datetime"  id="client sign date"/>

                  </div>
                  
                  <div class="col-md-6">
                  
                     <base-input  type="date" label="Date Of Tech Signature"  v-model="model.tech_sign_datetime"  id="tech sign date"/>

                  </div>
                </div>
                
                 <!-- end row -->
                 
            
                 
                 
                 <div class="row">
                 
                </div>
                
                 <!-- end row -->
                 
                 
                 
               
                 
                   <hr/> 
               
               <div class="row">
				  <div class="col-md-3">
						<div style="margin-top:50px; margin-bottom:30px;">
							<span class="heading-title text-warning"  style="font-weight:bold">Others</span>
						</div>
				  </div>	
				</div>
				
				
				
                <div class="row" v-if=0>
                  <div class="col-md-6">
                  		   <base-input label="Service Required">
                   				 <textarea class="form-control" v-model="model.service_required"  rows="6"></textarea>
                 			</base-input>
                  </div>
               
               
                <div class="col-md-6">
                  		    <base-input label="Service Rendered">
                    			<textarea class="form-control" v-model="model.service_rendered"  rows="6"></textarea>
                			 </base-input>
                  </div>
                  
                  
                </div>
               <!-- end row -->
               
               
               
                <div class="row" >
                  <div class="col-md-6">
                  		   <base-input label="Recommendations">
                  				  <textarea class="form-control" v-model="model.recommendation"  rows="8"></textarea>
                			 </base-input>
                  </div>
               
               
                <div class="col-md-6">
                  		     <base-input label="Follow-up ">
                    				<textarea class="form-control" v-model="model.followup"  rows="8"></textarea>
                			 </base-input>
                  </div>
                  
                  
                </div>
               <!-- end row -->
                 
                 
               
                 
                 
                  
                
                
                
                 
                 
                 
                  
                  
                
                <div>
               			 <base-button type="success" block native-type="submit">Save Report</base-button>
                		
                		
                </div>
                
                <div style="margin-top:20px;">
                		<base-button type="info"  @click="generate_report">Generate New Report PDF</base-button>
                </div>
              
              </form>
            </card>
            <!-- end card -->
            
            
            
	
	
		<div class="row">
					  <div class="col-md-12">
				<div style="margin-bottom:50px;">
						<base-button v-if="0" type="default"  @click="add_client_signature">Add Client Signature</base-button>
						
						
					<!--	<base-button v-if="pdf_exist==1" type="info"  @click="view_report_pdf">View Report PDF</base-button> -->

				  </div>


	
			</div>
		</div>	<!-- end row -->
            
            
            	
			 <!-- <div class="card">
				<div style="text-align:center;padding:30px;">
					<h3 class="mb-0"><span style="font-weight:normal"><a :href=pdf_link target="_blank">View Report PDF</a></span></h3>
				</div> 	
			</div>-->
            
            
            
            </div><!-- end col -->
		
		
		
		
		
		
		
			<div class="col-lg-12" >
			
			<!-- start card -->
			
			

			
			  <div v-for = "cl in checklists" class="card" style="" v-if="model.report_type==1">
				<div class="border-0 card-header">
					<h3 class="mb-0">
		
					<span style="">Checklist: </span>  {{cl.checklist_name}} 
						<br/>
						 <span v-if="0" style="font-weight:normal;font-size:12px;">  {{cl.checklist_name}} </span>
					</h3>
		
				</div>

				<el-table class="table-responsive table-flush"
						  header-row-class-name="thead-light"
						  :data="cl.items">
						  
					  <el-table-column width="90px" align="right" label="Done">
						<div slot-scope="{$index, row}" class="d-flex">
							 

						  <base-input >
							  <base-checkbox v-model="model.checklist_items_cb[row.id]" >
						
							  </base-checkbox>
							</base-input> 
				  
				
				  
				  
						</div>
					</el-table-column>   
					
					
					<el-table-column label="Item Name"
									 min-width="100px"
									 prop="checklist_item_name"
									 sortable>
						<template v-slot="{row}">
							<div class="media align-items-center">
								<div class="media-body">
									<span class="font-weight-600 name mb-0 text-sm">{{row.checklist_item_name}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
			

			 
			
				 	
		   
				</el-table>

	   
			</div>
			<!-- end card -->
			
			
			
			
			<!-- start card -->
			
			 <div  class="card" style="padding-bottom:0px;"  v-if="showTab == 2">
			 	    <base-button block type="default"  class=""  @click="toggleShowSelectAllBox">
						Show/Hide Quick Select Checklist Items
					</base-button>
			<div v-if="showSelectAllBox">		
					<div style="padding:20px;">
					Click on a checklist item to select/deselect it for all assets.
					 
					</div>
			 		<div v-for="cl in checklists" style="padding:20px;margin-bottom:30px;">
			 		
			 				<div style='font-weight:bold;color:#000'> {{cl.checklist_name}}</div>
			 				<div v-for="cli in cl.items" style="color:#000;cursor:pointer;">
			 						- {{cli.checklist_item_name}} 
			 						<span style="font-size:12px;color:orange;">
			 						  <span @click="selectAllOfClItem(cli.id, true)"> Select All </span> | <span @click="selectAllOfClItem(cli.id, false)"> Deselect All </span>
			 						</span>
			 				</div>
			 		</div>
			 	</div>
			 </div>
			
						
			 
	
			
			  <div  class="card" style="padding-bottom:80px;"  v-if="showTab == 2" :key="componentKey">
				<div class="border-0 card-header" style="margin-bottom:50px;">
					<h3 class="mb-0">
		
					<span style="">Assets</span>
					</h3>
		
				</div>
				
				
				
				<el-table class="table-responsive table-flush"
						  header-row-class-name="thead-light"
						  :data="assets"
						   v-if="model.report_type!=4"
						  >
						  
					  <el-table-column width="200px" align="left" label="Asset ID Tag" prop="asset.asset_tag_id">
					</el-table-column>  
					
					 <el-table-column width="200px" align="left" label="Name" prop="asset.product.product_name">
					</el-table-column>    
					
					
					<el-table-column label="Asset Location"
									 min-width="150px"
									 prop="checklist_item_name"
									 sortable>
						<template v-slot="{row}">
							<div class="media align-items-center">
								<div class="media-body">
									<span class="font-weight-600 name mb-0 text-sm " style="">{{row.asset.client_address.address}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
			
				</el-table>
				
				
				
				
				
		<!--
			<div class="row" style="margin-bottom:25px; padding-left:20px;margin-left:30px;">
				
				 
				  <div class="col-md-5" style="text-align:left">
				  	<h3>Asset Tag ID</h3>
				  </div>
				 
				 
				  <div class="col-md-3 " style="text-align:center">
				  	<h3>Model</h3>
				 </div>
			</div>
			-->
	
				
				
		<div v-for="asset in assets"  style="margin-bottom:20px;1padding-left:20px;1margin-left:30px;" 
			 v-if="model.report_type==4"
			
		>
			
			
		
			
      		<div class="" style="border-top:1px dotted #333;padding-top:25px;"  @click="showAssetChecklist(asset.asset.id)">
      		
      		
              
              
              <div style="text-align:left;cursor:pointer;width:100%;height:50px;">
                <div style="color:#000;">
                  <div style="display:block; float:left;padding-left:15px;">
                    <base-button type="warning" size="sm" class=""  >
						Show/Hide
					</base-button>
					</div>
                	<div style="float:left;padding-left:10px;padding-bottom:10px" > {{asset.asset.asset_tag_id}} <span style="font-size:11px;">
                			<br/>Model: {{asset.asset.product.model_number}}</span></div>
                </div>
              </div>
             <!-- <div class="col-md-3" style="text-align:center;cursor:pointer" @click="showAssetChecklist(asset.asset.id)">
                <div style="color:#000;">
                	<span >{{asset.asset.product.model_number}}</span>
                </div>
              </div>-->
              
              
              
              
            </div>
        
        
       
        <div v-if="asset_checklist_hideshow[asset.asset.id] && !asset.checklist" >
        	No checklist has been assigned to this equipment subtype. Please assign a checklist.
        	
        </div>
       
   
       
       
       
                       
       <div v-if="asset_checklist_hideshow[asset.asset.id] && asset.checklist" style="background-color:#f3f3f3;padding:20px;border-bottom:12px solid #888;display:block;clear:both;margin-bottom:-20px" >
	  		
	  		
	  <!-- start: parameters -->
       <div style="margin-bottom:50px;">
       
       
        <div style="text-align:left;margin-bottom:20px;" v-if="asset.parameters"><h3>Operating Parameters</h3></div>
        
      	<div v-for="parameter in asset.parameters" class="row">
                  <div class="col-md-7">
                  
                  {{parameter.parameter_name}}
                  <base-input 
                      name="parameter value"
                      placeholder=""
                      
                      size = 5
                      v-model="parameter.parameter_value">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
    	 </div>
                
         <!-- end row -->
       
       
       </div>
       <!-- end: parameters -->
       
       
	  		
	  	<div style="text-align:left;margin-bottom:35px;"><h3>{{asset.checklist.checklist_name}}</h3></div>
	  	
	    <div  style="text-align:left;margin-bottom:50px;">
	    	<base-button type="default" size="sm" class=""  @click="checkAssetAllChecklistItem(asset.asset.id)">
	    		Select All
	    	</base-button>
	    	
	    	<base-button type="warning" size="sm" class=""  @click="uncheckAssetAllChecklistItem(asset.asset.id)">
	    		Unselect All
	    	</base-button>

	    </div>
       
       
      	 	<el-table class="table-responsive table-flush"
						  header-row-class-name="thead-light"
						  :data="asset.checklist.items">
						  
					  <el-table-column width="90px" align="right" label="Done">
						<div slot-scope="{$index, row}" class="d-flex">
							 

						  <base-input >
							  <base-checkbox v-model="model.asset_checklist_items_cb[asset.asset.id][row.id]" >
							  
							  </base-checkbox>
							</base-input> 
				  
				
				  
				  
						</div>
					</el-table-column>   
					
					
					<el-table-column label="Item Name"
									 min-width="100px"
									 prop="checklist_item_name"
									 sortable>
						<template v-slot="{row}">
							<div class="media align-items-center">
								<div class="media-body">
									<span class="font-weight-600 name mb-0 text-sm">{{row.checklist_item_name}}</span>
								</div>
							</div>
						</template>
					</el-table-column>
			

			 
			
				 	
		   
				</el-table>
				
		<!-- end asset checklist table-->
       </div> <!-- end checklist-->
       
       
    </div> <!-- end assets -->
				
				
	   
			</div>
			<!-- end card -->

            	</div> <!-- end col -->
            </div> <!-- end row -->
	
    
    
    
  
	

    
    
   

	
	
            
		
</div>
<!-- end clients list -->    
 <div style="min-height:120px;"></div>

  
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 report:{
     	 	client:[]
     	 },
     	 client: [],
     	 assets:[],
     	 checklists:[],
     	 componentKey:333,
     	 showTab: 1,
     	 report_saved: 0,
		 pdf_exist: 0,
		 showSelectAllBox:0,
     	 pdf_link:'',
     	 has_checklist:1,
     	 asset_checklist_hideshow:[],
     	 model:{	
     	 	service_type:1,
     	 	assets:[],
     	 	checklist_items_cb:[],
     	 	attended_by:'',
     	 	asset_checklist_items_cb:[],
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 report_type_options: [
          {
            label: 'Maintenance',
            value: 1
          },
          /*{
            label: 'Repair',
            value: 2
          },*/
          {
            label: 'Adhoc',
            value: 3
          },
          {
            label: 'Maintenance - Version 2',
            value: 4
          },
        ],
	
      };
    },
    methods: {
    	changeTab(tab){
    		this.showTab = tab;
    	},
    	showAssetChecklist(asset_id){

    		if(!this.asset_checklist_hideshow[asset_id]){
    		
    			this.asset_checklist_hideshow[asset_id] = true;
    			
    			
				
    		}
    		else{
    			this.asset_checklist_hideshow[asset_id] = false;
    		}
    		
    		var new_obj = Object.assign({}, this.asset_checklist_hideshow,
				  { asset_id: this.asset_checklist_hideshow[asset_id] }
			);
				
			this.asset_checklist_hideshow = new_obj;
    	},
       
       checkAssetAllChecklistItem(asset_id){
    		
    		var vm = this;
    		this.checklists.forEach(function(cl) {
					 		
					 cl.items.forEach(function(cl_item) {
					 			
					 			vm.model.asset_checklist_items_cb[asset_id][cl_item.id] = true;
					 })
			});
			
			this.updateAssetsSection();
			//this.showAssetChecklist(asset_id);
		
    	},
    	uncheckAssetAllChecklistItem(asset_id){
    		
    		var vm = this;
    		/*this.building_asset_ids[building_id].forEach(function(aid) {
				vm.model.assets_cb[building_id][aid] = false;
			});*/
			
			/*
			this.model.asset_checklist_items_cb[asset_id].forEach(function(id) {
				vm.model.asset_checklist_items_cb[asset_id][id] = false;
			});
			*/
			
			this.checklists.forEach(function(cl) {
					 		
					 cl.items.forEach(function(cl_item) {
					 			
					 			vm.model.asset_checklist_items_cb[asset_id][cl_item.id] =false;
					 			
					 })
			});
			
			this.updateAssetsSection();
			//this.showAssetChecklist(asset_id);

			//console.log(this.model.asset_checklist_items_cb);
			
		
			
			
    	},
    	toggleShowSelectAllBox(){
    		if(this.showSelectAllBox==1){
    			this.showSelectAllBox = 0;
    		}
    		else{
    			this.showSelectAllBox = 1;
    		}
    	},
    	selectAllOfClItem(item_id, checked_option){
  		
  		//this.model.asset_checklist_items_cb[asset_id][cl_item.id]
  				
				var vm = this;
				
				this.assets.forEach(function(asset) {
					 
					 if(asset.asset_id in vm.model.asset_checklist_items_cb){
					 	vm.model.asset_checklist_items_cb[asset.asset_id][item_id]= checked_option;
					 }
				});
  					
  				this.updateAssetsSection();
  				swal.fire({
										title: `Success`,
										text: 'Checklist items for all assets have been selected/deselected',
										buttonsStyling: false,
										confirmButtonClass: 'btn btn-success',
										icon: 'success'
				});
  					
  		
  		},
    	
    	
    	updateAssetsSection(){
    	
    		if(this.componentKey == 333){
  						this.componentKey= 4555;
  					}
  					else{
  						this.componentKey= 333;
  					}
    	},
    	
    	fetch_checklist(){
    	
    			this.submit_form("fetch");

    	
    	},
  		submit_form(type){
  		
  				swal.showLoading();
  				
  				this.model.assets = this.assets;
  				
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/update_report';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				
			 				
			 				if(type == "fetch"){
			 					this.page_load();
			 				}
			 				else{
			 					this.report_saved = 1;
								swal.fire({
										title: `Success`,
										text: 'Report Updated.',
										buttonsStyling: false,
										confirmButtonClass: 'btn btn-success',
										icon: 'success'
								});
							}
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		generate_report(){
  			if(this.report_saved == 0){
  			
  					swal.fire({
									title: 'Error',
									text: "Please save your report before generating the report PDF.",
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
  			
  			
  				return;
  			}
  			else{
  		
  		
  			swal.showLoading();
			let uri = this.$base_url+'/api/reports/get/generate_report/'+this.$route.params.rid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 
				 if(response.data.success == "y"){
				 			this.pdf_exist = 1;
			 				swal.fire({
									title: `Success`,
									text: 'Report Generated.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							}).then((result)=> {
								if (result.isConfirmed) {
									this.$router.push( '/view-report/'+this.$route.params.rid)
								}
			
							});;
							
							
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
				
							
				
   
				})
  		
  			}//end if-else
  		
  		},
  	
  		add_client_signature(){
  		
  			this.$router.push( '/add-signature/'+this.$route.params.rid+"/1");
  			
  		},
  		delete_report(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are deleting this report',
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 let uri = this.$base_url+'/api/reports/post/delete_report/'+ this.$route.params.rid;
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Report Deleted.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 }).then((result)=>{
										   		
										 		vm.$router.push( '/manage-reports/')
										 		
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		},
  		view_report_pdf(){
  			this.$router.push( '/view-report/'+this.$route.params.rid)
  		},
  		viewClient(index, row) {
  			this.$router.push( '/manage-client/'+row.id)
  		
  		},
  		viewReport() {
  			this.$router.push( '/view-report/'+this.$route.params.rid)
  		},
  		addReport(index, row) {
  			this.$router.push( '/add-report/'+row.id)
  		
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/view_report/'+this.$route.params.rid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;

				 this.report = response.data.report;
				 //this.pdf_link = response.data.pdf;
				 
				 if(response.data.pdf_exist == 1){
				 	this.pdf_exist = 1;
				 }
				
				 
				 this.checklists = response.data.checklists;
				 
				 this.model = response.data.report;
				 this.model.checklist_items_cb = response.data.all_checklist_items_cb;
				 this.model.report_id = this.report.id;
				 this.assets = response.data.assets;
				
				this.model.asset_checklist_items_cb = response.data.assets_checklist_items_cb;
			
			
				this.has_checklist = response.data.has_checklist;
				
				
				//console.log(this.model.asset_checklist_items_cb);
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














