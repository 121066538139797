<template>
  <div>
 

 <div style="min-height:120px;"></div>
    
    
 <div class="container-fluid mt--6">
    
    
    <div style="text-align:right;padding:30px 15px;">
		<base-button
                    @click.native="goToAddProduct()"
                    class="edit"
                    type="default"
                    
                    icon
                  >
                  Add Equipment
    	</base-button>
	</div>
	
	
	
	  <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">All Equipment</h3>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="products">
         
         <!--         
         <el-table-column label="Equipment Name"
            				 min-width="220px"
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.product_name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
        	-->
        	
           <el-table-column label="Brand"
           					 min-width="120px"
                             prop="brand_name"
                             sortable />
                             
                                 
             <el-table-column label="Model No."
             				 min-width="200px"
                             prop="model_number"
                             sortable />
        

			<el-table-column label="Equipment Type"
							min-width="200px"
                             prop="equipment_type_name"
                             sortable />
                             
                             
            <el-table-column label="Subtype"
            				 min-width="180px"
                             prop="equipment_subtype_name"
                             sortable />



                             
            
           <el-table-column  align="right" label="Actions" min-width="180px">
                <div slot-scope="{$index, row}" class="d-flex">
                             

            
                  
                  
                  
                    <base-button
                    @click.native="editProduct($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  Edit
                  </base-button>
                  
                  
                  
                </div>
            </el-table-column>   	
           
        </el-table>

       
    </div>
	
	

      <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Add Brand</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Brand Name"
                      name="brand name"
                      placeholder="Brand name"
                      required
                      v-model="model.brand_name">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                
                <div>
                <base-button type="info" block native-type="submit">Add Brand</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div>
            
      
      
      
     




    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 brand_options:[],
     	 is_create: 1,
     	 products:[],
     	 model:{
     	 	model_number:'',
     	 	product_name:'',
     	 	equipment_subtype: '',
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 selectOptions: [
          {
            label: 'Alerts',
            value: 'Alerts'
          },
          {
            label: 'Badges',
            value: 'Badges'
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				vm.model.is_create  = this.is_create;
  				
  				let uri = vm.$base_url+'/api/products/post/create_brand';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Brand Added.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		editProduct(index, row) {
  			this.$router.push( '/update-product/'+row.id)
  		
  		},
  		
  		goToAddProduct(){
  			this.$router.push( '/update-product/0')
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/products/get/list';
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.et_type_options = response.data.et_arr;
				 this.et_subtype_all_options = response.data.est_arr;
				 this.brand_options = response.data.brands_arr;
				 this.products = response.data.products;
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














