<template>
  <div>
 
 <div style="min-height:120px;"></div>
 
   <div class="container-fluid mt--6">
    	  <card header-classes="bg-gradient-default">
            <div slot="header" class="row align-items-center">
              <div class="col text-center">
                <h3 class="h3 text-white">
                	<span >Checklist Information</span>
        
                </h3>
              </div>
            </div>

            <div>
                <span class="h6 surtitle">Name Of Checklist</span>
                <div class="h3 ">{{checklist.checklist_name}}</div>
             </div>
             

             
              <div style="margin-top:30px;">
                <span class="h6 surtitle">Remarks</span>
                <div class="h3 ">
                	<span> {{checklist.remarks}}</span>
                
                </div>
             </div>
             
           
        
          
          
          </card>
</div><!-- end container -->


 <div style="min-height:120px;"></div>
 <div class="container-fluid mt--6">

     <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">Checklist Items</h3>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="checklist_items">
            <el-table-column label="Item Name"
                             min-width="220px"
                             prop="checklist_item_name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.checklist_item_name}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            

             
            
           <el-table-column min-width="180px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                             

                  <base-button
                    @click.native="deleteChecklistItem($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  Delete
                  </base-button>
                  
                
                  
                  
                </div>
            </el-table-column>   	
           
        </el-table>

       
    </div>
</div>
<!-- end checklist items list -->    
 <div style="min-height:120px;"></div>

    
    
    
    
 <div class="container-fluid mt--6">
    
	  <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Add Item To Checklist</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Item Name"
                      name="checklist item name"
                      placeholder=""
                      required
                      v-model="model.checklist_item_name">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
    
                
                <div>
                <base-button type="info" block native-type="submit">Add Item</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div>
            
    </div>  <!--End container-->
    
     <div style="min-height:100px;"></div>
    
    
     <div class="container-fluid mt--6">
    
	  <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Update Checklist Info</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form_update_checklist">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Checklist Name"
                      name="checklist name"
                      placeholder=""
                      required
                      v-model="checklist.checklist_name">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                  <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Remarks"
                      name="remarks"
                      placeholder=""
                      required
                      v-model="checklist.remarks">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
    
                
                <div>
                <base-button type="info" block native-type="submit">Update Checklist</base-button>
                </div>
              
              </form>
            </card>
            	<base-button type="danger" @click="delete_checklist()" style="margin-top:10px;"  >Delete Checklist</base-button>
            	
            	
            </div>
            </div>
            </div>
            
    </div>  <!--End container-->
    
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 checklist:{
     	 	checklist_name:"",
     	 	equipment_subtype:[],
     	 },
     	 checklist_items:[],
     	 model:{
     	 	checklist_item_name:'',

     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/checklists/post/add_checklist_item';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Checklist Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
							
							vm.page_load();
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
							
			 			
			 		}
			 })//end api service
  		},
  		
  		submit_form_update_checklist(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/checklists/post/update_checklist_info/' + this.model.checklist_id;
				ApiService.post(uri, vm.checklist).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Checklist Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
							
							vm.page_load();
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
							
			 			
			 		}
			 })//end api service
  		},
  		viewChecklist(index, row) {
  			this.$router.push( '/manage-checklist/'+row.id)
  		
  		},
  		
  		deleteChecklistItem(index, row) {
  			this.to_confirm(row.id);
  		},
  	
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/checklists/get/checklist/'+this.$route.params.clid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.checklist = response.data.checklist;
				 this.checklist_items = response.data.checklist_items;
				 
				 this.model.checklist_id = response.data.checklist.id;
				 
				 
				 //clear fields
				 this.model.checklist_item_name = "";
				 swal.close();
   
			})
  		
  		
  		
  		},
  		delete_checklist(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are deleting this checklist',
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 let uri = this.$base_url+'/api/checklists/post/delete_checklist/'+ this.$route.params.clid;
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Checklist Deleted.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 }).then((result)=>{
										   		
										 		vm.$router.push( '/manage-checklists/')
										 		
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		},
  		to_confirm(item_id){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are deleting this item from the checklist',
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 let uri = this.$base_url+'/api/checklists/post/delete_checklist_item/'+ item_id;
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Item Deleted.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 }).then((result)=>{
										   		
										 		vm.page_load();
										 		
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














