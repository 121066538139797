<template>
  <div>
 
   
 <div style="min-height:120px;"></div>

    
 <div class="container-fluid mt--6">
    
	  <!--start new row-->
      
      <div class="row">
        <div class="col-lg-6">
          <div class="card-wrapper">
            <!-- Input groups -->
            <card>
              <!-- Card header -->
              <h3 slot="header" class="mb-0">Manage My Account</h3>
              <!-- Card body -->
              <form class="needs-validation"   @submit.prevent="submit_form">
                <!-- Input groups with icon -->
        
                <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Company Name"
                      name="company name"
                      placeholder=""
                      required
                      v-model="model.company_name">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                  <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Company Address Line 1"
                      name="company address"
                      placeholder=""
                      required
                      v-model="model.address">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                   <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Company Address Line 2"
                      name="company address"
                      placeholder=""
                      v-model="model.address_2">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                    <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Company Address Postal Code"
                      name="postal code"
                      placeholder=""
                      v-model="model.postal_code">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->
                 
                 
                    <div class="row">
                  <div class="col-md-6">
                  
                  <base-input label="Company UEN"
                      name="company uen"
                      placeholder=""
                      
                      v-model="model.uen">
          			</base-input>
          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                 <!-- end row -->

                
             
                  
                  
                
                <div>
                <base-button type="info" block native-type="submit">Update Details</base-button>
                </div>
              
              </form>
            </card>
            </div>
            </div>
            </div>
            

	
	<!-- upload logo -->
     <div   class="col-lg-6">   
      <card>
       <div class="row">
                  <div class="col-md-12">
                 	<h4>Upload Company Logo</h4>
                           <input type="file" id="file" ref="new_file" class="btn btn-default" />

          
          
        
                  </div>
                  <div class="col-md-6">
                  </div>
                </div>
                
                
                
     <!-- end row -->
     	<div style="margin-top:50px; text-align:left;">
			<base-button @click="uploadPhoto" type="info"  native-type="submit">Upload Logo</base-button>

     	</div>
     	
      </card>
      </div>
     
     

    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 is_create: 1,
     	 equipment_types:[],
     	 inputs: {
          fileSingle: [],
          fileMultiple: []
        },
     	 model:{
     	 	company_name:'',
     	 	address:'',
     	 	uen:'',

     	 },

     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				
  				vm.model.is_create  = this.is_create;
  				
  				let uri = vm.$base_url+'/api/clients/post/edit_organization';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Organization details updated',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
							
							vm.page_load();
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		uploadPhoto(){
    		
    			swal.showLoading();
  				var vm = this;

				let uploaded_file = this.$refs.new_file.files[0];
      			let formData = new FormData();
  			    formData.append('file', uploaded_file);    
  			    //formData.append('client_id', this.model.id);
  			    //formData.append('kd_document_name', this.new_key_document.document_name);
  			    //formData.append('kd_remarks', this.new_key_document.remarks);
  			    
  			    
  			      				
  				let uri = vm.$base_url+'/api/clients/post/upload_org_logo';
				ApiService.post(uri, formData).then(response => {
					var msg = "Photo Uploaded.";
					
					
			
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: msg,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service			
    	
    	
    	
    	
    	},
  		viewEquipment(index, row) {
  			this.$router.push( '/manage-equipment/'+row.id)
  		
  		},
  	
  		et_change(){
  			
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/my_organization';
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.model = response.data.organization;
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














