<template>
  <div>
 

 <div style="min-height:120px;"></div>
 
   <div class="container-fluid mt--6">
   
       <div class="row">
        <div class="col-lg-6">
			<card >
				<div slot="header" class="row align-items-center">
				  <div class="col text-center">
					<h3 class="h3 ">
						<span >Select Assets For New Report</span>
		
					</h3>
				  </div>
				</div>

				<div style="text-align:center;">
					<span class="h6 surtitle">Client</span>
					<div class="h3 ">{{client.client_name}}</div>
				 </div>
			 
			
			 
		
		  
		  
			  </card>
		</div>
	</div> <!-- end row-->	  
			  
</div><!-- end container -->


 <div style="min-height:120px;"></div>

          
          
          
  <div class="container-fluid mt--6">

     <card v-for="address in addresses" class="card" style="margin-bottom:150px;" header-classes="bg-gradient-default">
      <div slot="header" class="row align-items-center">
              <div class="col text-center">
                <h3 class="h3 text-white">
                	<span >{{address.address}}</span>
        
                </h3>
              </div>
            </div>
        
          <div class="row" style="text-align:center;margin-bottom:50px">
                  	<div class="col-md-6">
                  		 <div style="margin-top:30px;">
							<span class="h6 surtitle" style="color:#525f7f">Location Address</span>
							<div >
								<span style="font-size:13px;"> {{address.address}}</span>
							</div>
						 </div>
					 </div>
					 <div class="col-md-6">	 
						 <div style="margin-top:30px;">
							<span class="h6 surtitle">Postal Code</span>
							<div >
								<span style="font-size:13px;"> {{address.postal_code}}</span>
							</div>
						 </div>
      				 </div>
          </div>
                  
       
	  <div v-for="building in address.client_buildings" style="margin-bottom:100px;">
	  		
	  	<div style="text-align:center;margin-bottom:35px;"><h3>{{building.building_name}}</h3></div>
	  	
	    <div v-if=0 style="text-align:center;">
	    	<base-button type="default" size="sm" class=""  @click.native="addAsset(building)">
	    		<i class="text-white ni ni-fat-add"></i>

	    		Add Asset
	    	</base-button>

	    </div>
        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  :data="building.assets">
        	
        	<el-table-column  align="right" label="Select">
                <div slot-scope="{$index, row}" class="d-flex">
                            
                   <base-input >
					  <base-checkbox v-model="model.assets_cb[row.id]"  >
						
					  </base-checkbox>
					</base-input> 

                  
                  
                </div>
            </el-table-column>
            
            <el-table-column label="Asset ID Tag"
                           
                             prop="name"
                             sortable>
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.asset_tag_id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            
             <el-table-column label="Location" prop="location_description">
             </el-table-column>
             
             <el-table-column label="Level/Unit" sortable prop="level_num">
             </el-table-column>
             
             
             <el-table-column label="Equipment Type"   min-width="80px"  prop="product.equipment_type.equipment_type_name">
             </el-table-column>
             
             <el-table-column label="Equipment Subtype"   min-width="80px"  prop="product.equipment_subtype.equipment_subtype_name">
             </el-table-column>
            
              <el-table-column label="Remarks" prop="remarks">
             </el-table-column>
            
            
            
            
            
              	
           
        </el-table>
		<!-- end assets table-->
       
       </div> <!-- end building div -->
       
       
    </card> <!-- end addresses -->
</div>
<!-- end clients list -->   


	 		<div style="max-width:90%;text-align:center;margin-left:auto;margin-right:auto;">
                <base-button type="info" block @click="submit_report" >Create Report</base-button>
             </div> 
	

    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 client:[],
     	 code: "",
     	 addresses:[],
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 	assets_cb: [],
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
    	addAsset(row) {
  			this.$router.push( '/add-asset/'+this.$route.params.cid+"/building/"+row.id)
    	},
  		submit_report(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/add_report';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				
			 				
			 				vm.$router.push( '/edit-report-details/'+ response.data.report_id)
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewAsset(index, row) {
  			this.$router.push( '/manage-asset/'+row.id)
  		
  		},
  		selectAsset(row){
  			//alert("d:"+this.model.assets);
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
  			
			let uri = this.$base_url+'/api/clients/get/client_for_report/'+this.$route.params.cid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.client = response.data.client;
				 this.addresses = response.data.client.client_addresses;
				 this.model.assets_cb = response.data.assets_cb;
				 
				 this.model.client_id = this.$route.params.cid;
				 
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














