<template>
  <div>
 

 <div style="min-height:120px;"></div>
    
 
 
 
  
 <div class="container-fluid mt--6">
    
      <div class="row">
        <div class="col-lg-12">
        	<base-button type="warning"  class=""  @click="save_signature" style="margin-bottom:20px;">
        		Save My Signature
        	</base-button>
        	
      
       		<VueSignaturePad width="900px" height="400px" ref="signaturePad" :options="{backgroundColor:'#fff' }" />
        	
        	
        	
        	
        	<base-button type="danger" size="sm" class=""  @click="undo_signature">
        		Undo
        	</base-button>
        	
        	<base-button type="danger" size="sm" class=""  @click="clear_signature">
        		Clear
        	</base-button>

        	 <div style="min-height:30px;"></div>


        	
        	
        	

        </div>
      </div>
            
      
      




    
    </div>  <!--End container-->
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  import VueSignaturePad from 'vue-signature-pad';
  
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      VueSignaturePad,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 building:{
     	 	client_address:[],
     	 	
     	 },
     	 address:[],
     	 client: [],
     	
     	 model:{ 
     	 	report_id: 0,
     	 	sign_type: 0,
     	 	signature: 0,
     	 },
     	 product_options:[],
     	 
     	 selectOptions: [
          {
            label: 'Alerts',
            value: 'Alerts'
          },
          {
            label: 'Badges',
            value: 'Badges'
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/reports/post/add_my_signature';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Report signed.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							}).then((result)=> {	
				  
				  		 			if (result.isConfirmed) {
				  		 			
				  		 				this.$router.push( '/view-report/'+this.$route.params.rid)
				  		 			
				  		 			}
				  		 			
				  		 	});
							
							
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		et_change(){
  			
  		
			
  		},
  		save_signature(){
  			const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      		//console.log(isEmpty);
      		//console.log(data);
      		
      		if(!isEmpty){
      			this.model.signature = data;
      			this.submit_form();
      		}
  		},
  		undo_signature(){
			this.$refs.signaturePad.undoSignature();
  		},
  		clear_signature(){
			this.$refs.signaturePad.clearSignature();
  		},
  		page_load(){
  			this.model.report_id = this.$route.params.rid;
  			this.model.sign_type = this.$route.params.type;
  			
  			/*
  			
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/add_building_info/'+ this.$route.params.cid +"/"+this.$route.params.adid;
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;
				 this.client = response.data.client;
				 this.address = response.data.address;
				 
				 this.model.client_id = this.$route.params.cid;
				 this.model.client_address_id = response.data.address.id; 
				 
				 swal.close();
   
			})*/
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














