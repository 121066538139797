<template>
  <div>
 

 <div style="min-height:120px;"></div>
  <div class="container-fluid mt--6">

     <div class="card">
        <div class="border-0 card-header">
            <h3 class="mb-0">All Reports</h3>
        </div>

        <el-table class="table-responsive table-flush"
                  header-row-class-name="thead-light"
                  @row-click="rowEditReport"
                  :data="reports">
            <el-table-column label="Report ID"
            				 min-width="120px"
                             prop="name"
                             >
                <template v-slot="{row}">
                    <div class="media align-items-center">
                        <div class="media-body">
                            <span class="font-weight-600 name mb-0 text-sm">{{row.id}}</span>
                        </div>
                    </div>
                </template>
            </el-table-column>
            
              <el-table-column label="Client" sortable  min-width="200px"   prop="client_name">
             </el-table-column>
             
             <el-table-column label="Type"
             				 min-width="150px"
                             >
                <template v-slot="{row}">
                   <span v-if="row.report_type==1">Maintenance</span>
                   <span v-if="row.report_type==3">Adhoc</span>
                   <span v-if="row.report_type==4">Maintenance V2</span>
                   
                                   
                </template>
            </el-table-column>
            
            
             
             
             <el-table-column label="Status"
             				 min-width="150px"
                             >
                <template v-slot="{row}">
                   <span v-if="row.report_status==1">Draft</span>
                   <span v-if="row.report_status==2">Signed By Client</span>
                   <span v-if="row.report_status==3">Emailed To Client</span>
                   
                   
                   <div v-if="row.flagged==1" style="color:#fff;font-weight:bold;text-align:center;;background-color:red; padding:2px;">Flagged</div>
                   
                </template>
            </el-table-column>
             
             
            <el-table-column label="Report Date"  min-width="130px" sortable  prop="report_datetime_str">
             </el-table-column>
             
             
               <el-table-column label="Location"  min-width="150px"  >
                <template v-slot="{row}">
                   <span style="font-weight:bold;">{{row.location}}</span>
               		</br>
                      <span >{{row.building_info}}</span>
                </template>
             </el-table-column>
             
            
             
            <el-table-column label="Created By"  min-width="130px"  prop="user.user_info.name">
             </el-table-column>


			 <!-- <el-table-column label="Assigned To"  min-width="130px"  prop="">
             </el-table-column>  -->            
            
             
            
             
            
           <el-table-column min-width="280px" align="right" label="Actions">
                <div slot-scope="{$index, row}" class="d-flex">
                             

                
                  
                  
                  
                  <base-button
                    @click.native="editReport($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  Edit
                  </base-button>
                  
                  
                 <base-button
                    @click.native="viewReportPhotos($index, row)"
                    class="edit"
                    type="warning"
                    size="sm"
                    icon
                  >
                  Photos
                  </base-button>
                 
                 
                   <base-button
                    @click.native="viewReport($index, row)"
                    class="edit"
                    type="info"
                    size="sm"
                    icon
                    v-if="row.pdf_exist == 1"
                  >
                  View Report
                  </base-button>
                  
                  
                  
                </div>
            </el-table-column>   	
           
        </el-table>

       
    </div>
</div>
<!-- end clients list -->    
 <div style="min-height:120px;"></div>

  
    
    
    <div style="min-height:200px;"></div>

  </div>
</template>
<script>
  import { Select, Option, Table, TableColumn, Tabs, TabPane,} from 'element-ui'
  import ApiService from '@/services/api.service';
  import { TokenService } from '@/services/storage.service'
  import swal from 'sweetalert2';
  import 'sweetalert2/dist/sweetalert2.css';
  

  export default {
    components: {
  	  [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      TabPane,
      Tabs,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data() {
      return {
     	 reports:[],
     	 client: [],
     	 
     	 model:{
     	 	client_name:'',
     	 	client_address:'',
     	 	contact_number:'',
     	 	client_email: '',
     	 	contact_person: '',
     	 	remarks:'',
     	 	client_type:1,
     	 },
     	 et_type_options:[],
     	 et_subtype_options:[],
     	 et_subtype_all_options:[],
     	 client_type_options: [
          {
            label: 'Company',
            value: 1
          },
          {
            label: 'Individual',
            value: 2
          },
        ],
	
      };
    },
    methods: {
  		submit_form(){
  				swal.showLoading();
  				var vm = this;
  				let uri = vm.$base_url+'/api/clients/post/create_client';
				ApiService.post(uri, vm.model).then(response => {
			 		if(response.data.success == "y"){
			 				swal.fire({
									title: `Success`,
									text: 'Client Created.',
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'success'
							});
			 		}
			 		else{
			 			swal.fire({
									title: 'Error',
									text: response.data.error,
									buttonsStyling: false,
									confirmButtonClass: 'btn btn-success',
									icon: 'warning'
							});
			 			
			 		}
			 })//end api service
  		},
  		viewClient(index, row) {
  			this.$router.push( '/manage-client/'+row.id)
  		
  		},
  		viewReport(index, row) {
  			this.$router.push( '/view-report/'+row.id)
  		},
  		editReport(index, row) {
  			this.$router.push( '/edit-report-details/'+row.id)
  		},
  		rowEditReport(row){
  			this.$router.push( '/edit-report-details/'+row.id);
  		},
  		viewReportPhotos(index, row){
  			this.$router.push( '/manage-report-photos/'+row.id)
  		},
  		addReport(index, row) {
  			this.$router.push( '/add-report/'+row.id)
  		
  		},
  		et_change(){
  			
  			this.et_subtype_options = this.et_subtype_all_options[this.model.equipment_type][0];
  			this.model.equipment_subtype = this.et_subtype_options[0]['value'];
			
  		},
  		page_load(){
  			swal.showLoading();
			let uri = this.$base_url+'/api/clients/get/all_reports';
			 ApiService.get(uri).then(response => {
			 
				 const vm = this;

				 this.reports = response.data.reports;
				 
				 swal.close();
   
			})
  		
  		
  		
  		},
  		to_confirm(){
  			
  		
  			var vm = this;
  			swal.fire({
					title: 'Are you sure?',
					text: 'You are changing the password for the virtual tour to:     '+ this.model.code,
					type: 'warning',
					showCancelButton: true,
					confirmButtonClass: 'btn btn-success btn-fill',
					cancelButtonClass: 'btn btn-danger btn-fill',
					confirmButtonText: 'Yes, proceed.',
					buttonsStyling: false
				  }).then((result)=> {	
				  
				  		 if (result.isConfirmed) {
				  		 	 var $base_url = "https://kh.rollingatlas.com/kh/public";
				  			 let uri = vm.$base_url+'/api/change_code';
							 ApiService.post(uri, vm.model).then(response => {
			 						if(response.data.success == "y"){
			 							vm.code = response.data.code;
			 							swal.fire({
												title: `Success`,
												text: 'Virtual Tour Password Updated.',
												buttonsStyling: false,
												confirmButtonClass: 'btn btn-success',
												icon: 'success'
										 });
			 						}
			 				})
				  		 
				  		 }
				  		 else{
				  		 
				  		 
				  		 }
				  			
			 
					}
					
					)//end swal

  		}
    },
    mounted() {
      	
      	this.page_load();
      	
    }
  };
</script>
<style lang="scss">
@media screen and (max-width: 820px) {
	.ec_company_header_mob{
		display:none;
	}	
}



</style>














