var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticStyle:{"min-height":"120px"}}),_c('div',{staticClass:"container-fluid mt--6"},[_c('div',{staticClass:"card"},[_vm._m(0),_c('el-table',{staticClass:"table-responsive table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.reports},on:{"row-click":_vm.rowEditReport}},[_c('el-table-column',{attrs:{"label":"Report ID","min-width":"120px","prop":"name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-body"},[_c('span',{staticClass:"font-weight-600 name mb-0 text-sm"},[_vm._v(_vm._s(row.id))])])])]}}])}),_c('el-table-column',{attrs:{"label":"Client","sortable":"","min-width":"200px","prop":"client_name"}}),_c('el-table-column',{attrs:{"label":"Type","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.report_type==1)?_c('span',[_vm._v("Maintenance")]):_vm._e(),(row.report_type==3)?_c('span',[_vm._v("Adhoc")]):_vm._e(),(row.report_type==4)?_c('span',[_vm._v("Maintenance V2")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Status","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.report_status==1)?_c('span',[_vm._v("Draft")]):_vm._e(),(row.report_status==2)?_c('span',[_vm._v("Signed By Client")]):_vm._e(),(row.report_status==3)?_c('span',[_vm._v("Emailed To Client")]):_vm._e(),(row.flagged==1)?_c('div',{staticStyle:{"color":"#fff","font-weight":"bold","text-align":"center","background-color":"red","padding":"2px"}},[_vm._v("Flagged")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"Report Date","min-width":"130px","sortable":"","prop":"report_datetime_str"}}),_c('el-table-column',{attrs:{"label":"Location","min-width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(_vm._s(row.location))]),_c('br'),_c('span',[_vm._v(_vm._s(row.building_info))])]}}])}),_c('el-table-column',{attrs:{"label":"Created By","min-width":"130px","prop":"user.user_info.name"}}),_c('el-table-column',{attrs:{"min-width":"280px","align":"right","label":"Actions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var $index = ref.$index;
var row = ref.row;
return _c('div',{staticClass:"d-flex"},[_c('base-button',{staticClass:"edit",attrs:{"type":"warning","size":"sm","icon":""},nativeOn:{"click":function($event){return _vm.editReport($index, row)}}},[_vm._v(" Edit ")]),_c('base-button',{staticClass:"edit",attrs:{"type":"warning","size":"sm","icon":""},nativeOn:{"click":function($event){return _vm.viewReportPhotos($index, row)}}},[_vm._v(" Photos ")]),(row.pdf_exist == 1)?_c('base-button',{staticClass:"edit",attrs:{"type":"info","size":"sm","icon":""},nativeOn:{"click":function($event){return _vm.viewReport($index, row)}}},[_vm._v(" View Report ")]):_vm._e()],1)}}])})],1)],1)]),_c('div',{staticStyle:{"min-height":"120px"}}),_c('div',{staticStyle:{"min-height":"200px"}})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"border-0 card-header"},[_c('h3',{staticClass:"mb-0"},[_vm._v("All Reports")])])}]

export { render, staticRenderFns }